@import "../scss/framework/tools";
@import "../scss/framework/variables";

.Main {
    position: absolute;
    @include respSize(right, 55, 10);
    top: 190px;

    z-index: 9;

    @include mq($until: mobile) {
        margin-top: respM(159);
        top: 0;
    }

    ul {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        li {
            @include respSize(margin-bottom, 18);

            width: 6px;
            height: 3px;
            background-color: $black;

            opacity: 0.5;

            transition: width .3s, background-color .3s;

            mix-blend-mode: difference;

            &:global(.active) {
                width: 16px;

                opacity: 1;
            }
        }
    }
}

[class*="bg-color--"] {
    .Main {
        li {
            background-color: $white;
        }
    }
}
