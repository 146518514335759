@import "../../scss/framework/tools";
@import "../../scss/framework/_variables";

.Main {
    position: relative;

    color: $black;

    z-index: 1;

    > div {
        display: flex;
        justify-content: space-between;

        @include mq($until: mobile) {
            overflow: hidden;
        }
    }
}

.MainImage {
    position: relative;
    top: resp(-90);
    width: susy-span(5 narrow of 12);
    margin-left: susy-span(2 wide of 12);

    @include respSize(padding-bottom, 100, 0);

    display: block;

    //IE11
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin-left: -165px;
    }

    @include mq($until: mobile) {
        top: initial;
        margin-top: respM(100);
        width: susy-span(11 narrow of 12);
        margin-left: susy-span(1 wide of 12);
    }

    figcaption {
        position: absolute;
        @include respSize(top, 390, 0);
        left: susy-span(4 wide of 5);
        width: susy-span(5 wider of 5);

        display: block;
        z-index: 2;

        @include mq($until: mobile) {
            position: relative;
            margin-top: -284px;

            width: susy-span(9 narrow of 11);
            left: susy-span(2 narrow of 11);
        }

        h2 {
            font-family: $Merriweather;
            display: flex;
            flex-direction: column;
            word-spacing: 30000px;
            font-size: 8rem;
            line-height: 1.3;

            @include mq($from: large) {
                font-size: 8rem;
            }

            @include mq($until: mobile) {
                font-size: 6rem;
                line-height: 6.4rem;
                margin-bottom: respM(25);
            }

            div {
                position: relative;
                overflow: hidden;
                line-height: 1.3;

                span:not(.TitleMask) {
                    opacity: 0;
                }
            }

        }

        p {
            font-size: 4rem;
            line-height: 6rem;
            width: 100%;


            @include mq($from: large) {
                font-size: 4rem;
            }

            @include mq($until: mobile) {
                font-size: 3rem;
                line-height: 4rem;
                width: 100%;
            }
        }
    }
}

.TitleMask {
    position: absolute;
    top: 0;

    width: 0;
    height: 100%;

    background-color: $white;
}

.Job {
    position: relative;
    @include respSize(margin-top, 200);

    @include mq($until: mobile) {
        position: absolute;
        left: susy-span(1 narrow of 12);
        bottom: respM(45);
        writing-mode: vertical-rl;
        text-orientation: sideways;

        max-height: 125px;
        text-align: right;

        span {
            position: relative;
            display: block;
            transform: rotate(180deg);
            transform-origin: center center;
            white-space: nowrap;
        }
    }
}

.JobImage {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    figcaption {
        position: relative;
        padding-right: resp(25);
        padding-top: resp(40);
        height: fit-content;
        writing-mode: vertical-rl;
        text-orientation: sideways;

        @include fluid-type(12px, 16px);

        //IE11
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            margin-left: -165px;
        }

        span {
            position: relative;
            display: block;
            transform: rotate(180deg);
            transform-origin: center center;

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
               transform: rotate(0deg);
            }

            @supports (-ms-ime-align: auto) {
                display: initial;
            }
        }
    }
}
