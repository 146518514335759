@import "../../scss/framework/tools";

.Wave {
    display: block;

    @include respSize(width, 60, 40);
    height: auto;

    overflow: hidden;

    @include mq($until: mobile) {
        svg {
            height: 100%;
            width: auto;
        }
    }
}

.Scroll {
    position: absolute;
    @include respSize(right, 55, 10);
    top: 50vh;
    margin-top: -50px;

    cursor: pointer;

    @include mq($until: mobile) {
        top: initial;
        bottom: 60px;
        right: initial;
        left: 50%;

        z-index: 9;
    }
}

:global {
    .closeAnimation {
        transform-origin: center;
        :local {
            animation: closeAnim 3s infinite linear;
        }
    }


}

@keyframes closeAnim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg)
    }
}
