@import "framework/_reset";
@import "../scss/framework/_variables";
@import "../scss/framework/css-variables";

@import "framework/tools";

$index: 1, 2, 3, 4;

body {
    font-family: $Montserrat;
    @include fluid-type(18px, 22px);
    line-height: 1.3;

    background-color: $white;
    transition: background-color 2s;

    @each $val in $index {
        &.bg-color--#{$val} {
            background-color: #{var(--speakerColor0#{$val})};
        }
    }
}

* {
    color: $black;
    transition: fill 1s, color 1s;
}

[class*="bg-color--"] {
    * {
        color: $white !important;

        svg path {
            fill: $white !important;
        }
    }
}

h4 {
    font-family: $Merriweather;
    font-weight: bold;
    @include fluid-type(16px, 20px);
    line-height: 4rem;

    @include mq($until: mobile) {
        font-size: 2rem;
        line-height: 3.4rem;
    }
}

p {
    @include fluid-type(14px, 16px);
    font-weight: 400;
    line-height: 3rem;

    @include mq($until: mobile) {
        font-size: 1.8rem;
        line-height: 3.4rem;
    }

    &:not(:last-child) {
        margin-bottom: resp(30);

        @include mq($until: mobile) {
            margin-bottom: respM(30);
        }
    }
}

svg {
    display: block;
}

[data-scrollbar] {
    height: 100vh;
    width: 100vw;
}

.lazyload {
    opacity: 0;
    transition: opacity .3s;
}

.lazyloading {
    opacity: 1;
}

.scrollbar-track {
    @include mq($until: mobile) {
        display: none !important;
    }
}

section:last-child [class*="Fullsize"] {
    @include mq($until: mobile) {
        padding-bottom: respM(200);
    }
}
