@import "../../scss/framework/tools";
@import "../../scss/framework/_variables";

.Main {
    position: absolute;
    bottom: resp(100);

    @include respSize(padding-bottom, 80);

    ul {
        opacity: 0;
        transition: opacity .8s;
    }

    &:global(.stuck), &:global(.reached) {
        color: $black;

        svg path {
            fill: $black;
        }

        ul {
            opacity: 1;
        }
    }
}

.Item {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    opacity: .3;
    transition: opacity .3s;

    cursor: pointer;

    &:last-child {
        opacity: .8;
    }

    &:nth-child(2) {
        margin-top: resp(95);
    }

    &:hover {
        opacity: 1;
    }

    figure {
        position: relative;
    }

    figcaption {
        position: absolute;
        top: 80px;
        @include respSize(left, 35);

        font-family: $Merriweather;
        @include fluid-type(14px, 16px);
        line-height: 2rem;
        word-spacing: 3000px;

        z-index: 3;
    }

}

.Arrow {
    display: block;

    &:nth-child(1) {
        margin-bottom: resp(55);
    }

    &:nth-child(2) {
        margin-top: resp(55);
    }
}
