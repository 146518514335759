$black: #000;
$white: #FFF;

$fontColor: $black;

$timelineBG: #E7E7E7;
$progressBG: $black;

$shareHover: #cccccc;

$shareBG: rgba(0,0,0,.8);

//Fonts
$Montserrat: 'Montserrat', Helvetica, Arial, sans-serif;
$Merriweather: 'Merriweather', Calibri, Times, serif;
$Josefin: 'Josefin Sans', 'Montserrat', Helvetica, Arial, sans-serif;
