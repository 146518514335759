@import "../scss/framework/_variables";
@import "../scss/framework/tools";

.Main {
    position: relative;

    width: 100vw;
    height: 100vh;
    max-height: 800px;

    color: $white;

    z-index: 9;

    > div {
        height: 100%;
        overflow: hidden;
    }

    footer {
        position: absolute;
        padding-top: 15px;
        padding-bottom: 15px;

        display: flex;
        align-items: center;
        justify-content: center;

        @include fluid-type(9px, 12px);
        color: $white;
        text-align: center;

        width: 100vw;
        background-color: $black;
        z-index: 9;

        opacity: 0;

        small {
            @include fluid-type(8px, 10px);

        }

        @include mq($until: mobile) {
            padding: respM(10) 0;
        }
    }
}

.Header {
    position: relative;
    @include respSize(top, 50, 30);
    top: resp(50);

    display: flex;
    justify-content: space-between;

    width: 100%;
    z-index: 2;

    @include mq($until: mobile) {
        margin-left: susy-span(1 narrow of 12);
        margin-right: susy-span(1 narrow of 12);

        width: auto;
    }
}

.LogoAudemarsPiguet {
    display: flex;
    flex-direction: column-reverse;

    figcaption {
        @include fluid-type(10px, 13px);

        @include mq($until: mobile) {
            text-indent: respM(20);
        }
    }
}

.MosaicImage {
    position: absolute;

    display: inline-block;
    box-shadow: 0 2px 60px 0 rgba(0,0,0,0.50);

    opacity: 0;

    img, figure, picture {
        width: 100%;
        height: auto;
    }
}

.MastheadText1, .MastheadText2 {
    position: absolute;

    display: flex;
    justify-content: center;

    width: 100%;

    color: $white;

    opacity: 0;
    transition: color .4s;

    pointer-events: none;

    @include mq($until: mobile) {
        flex-direction: column;
        align-items: center;
    }
}

.MastheadText1 {
    @include respSize(top, 110, 117);

    font-family: $Josefin;
    font-weight: 200;
    @include fluid-type(150px, 210px);

    line-height: 1;


    @include mq($from: large) {
        font-size: 21rem;
    }

    @include mq($until: mobile) {
        font-size: 11rem;
        line-height: 10rem;
    }
}

.MastheadText2 {
    position: relative;
    @include respSize(top, 556);

    z-index: 5;

    font-family: $Montserrat;
    @include fluid-type(120px, 160px);
    line-height: 10rem;

    @include mq($from: large) {
        font-size: 16rem;
    }

    @include mq($until: mobile) {
        bottom: initial;
        top: respM(380);
        font-size: 8rem;
        line-height: 9rem;
    }
}

.Twist {
    font-family: $Merriweather;
    font-weight: 300;

    transition: color .4s;


    &:after {
        content: '*';
        position: absolute;
        top: -30px;
        right: -20px;
        @include fluid-type(20px, 30px);
        font-family: $Montserrat;
    }
}
