@import "./scss/framework/_tools";

.Container {
    clear: fix;
    position: relative;
    margin: 0 auto;

    width: 100%;
    max-width: calc(1440px - (84px*2));
    box-sizing: border-box;

    @include mq($until: medium) {
        padding: 0 resp(20);
    }

    @include mq($until: mobile) {
        min-width: 100%;
        padding: 0;
        overflow-x: hidden;
    }
}
