@import "../scss/framework/tools";
@import "../scss/framework/_variables";

.Main {
    position: fixed;
    bottom: 70px;
    @include respSize(right, 40, 10);

    display: flex;
    flex-direction: column-reverse;

    min-width: 68px;
    height: 68px;
    border-radius: 50%;

    @include respSize(border-radius, 34);

    overflow: hidden;
    cursor: pointer;

    z-index: 99;

    ul {
        position: absolute;
        padding: 0;

        border-radius: 34px;

        width: 100%;
        height: 0;

        background-color: $shareBG;
        box-sizing: border-box;

        li {
            display: flex;
            align-items: center;
            justify-content: center;

            padding-top: 12px;
            padding-bottom: 12px;

            &:hover {
                svg * {
                    fill: $shareHover !important;
                }
            }
        }
    }

    &.Open {
        overflow: visible;
        ul {
            padding-top: resp(35);
            padding-bottom: 68px;
            height: auto;
        }
    }
}

.Icon {
    display: flex;
    align-items: center;
    justify-content: center;

    flex: none;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    background-color: $black;
    z-index: 2;
}
