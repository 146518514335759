@import "../../scss/framework/tools";
@import "../../scss/framework/_variables";

.Button {
    position: absolute;
    @include respSize(bottom, 135);

    left: 50%;
    @include respSize(margin-left, -(125/2), -(90/2));

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include respSize(width, 125, 90);
    @include respSize(height, 125, 90);


    border-radius: 50%;

    z-index: 2;

    opacity: 0;

    transform: scale(1);
    transition: transform .3s;

    &:hover {
        transform: scale(1.1);
    }

    span {
        @include respSize(margin-top, 10);

        font-family: $Montserrat;
        font-weight: 700;
        text-transform: uppercase;
        @include fluid-type(10px, 12px);
        color: $white;

        @include mq($until: mobile) {
            font-size: 1.2rem;
        }
    }
}

.Tracker {
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
