.Picture {
    position: relative;
    display: block;

    width: fit-content;
    height: fit-content;

    picture {
        display: block;
        width: fit-content;
    }
}

.FullSize {

}
