@import "../scss/framework/tools";

.Introduction {
    @include respSize(margin-top, 194, 90);
    @include respSize(margin-bottom, 100, 107);
    @include respSize(padding-bottom, 100, 0);
    margin-left: susy-span(2 wider of 12);
    width: susy-span(8 wider of 12);

    @include fluid-type(18px, 22px);
    line-height: 4.4rem;

    opacity: 0;

    @include mq($until: mobile) {
        margin-left: susy-span(1 narrow of 12);
        width: susy-span(10 wider of 12);
        line-height: 3.4rem;
    }
}

.Background {
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    opacity: 0;

    z-index: -1;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .Gradient {
        position: absolute;
        top:  resp(500);
        left: 0;
        right: 0;
        bottom: 0;
    }
}

