@import "../../scss/framework/tools";

.Main {
    padding-bottom: resp(150);
}

.Introduction {
    margin-left: susy-span(2 wide of 12);
    margin-top: resp(100);

    width: susy-span(6 wide of 12);

    &:not(:last-child) {
        margin-bottom: resp(90);
    }

    @include mq($until: mobile) {
        margin-top: respM(90);
        margin-left: susy-span(1 wide of 12);

        width: susy-span(10 wider of 12);

        &:not(:last-child) {
            margin-bottom: respM(50);
        }
    }
}

.Row {
    display: flex;
    margin-left: susy-span(2 narrow of 12);
    margin-bottom: resp(90);
    width: susy-span(9 wide of 12);

    @include mq($until: mobile) {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        margin-left: 0;
        margin-bottom: respM(50);

        width: 100%;
    }
}

.Column {
    position: relative;

    &:first-child {
        padding-top: resp(145);
        width: susy-span(4 wider of 9 wide);

        z-index: 2;

        @include mq($until: mobile) {
            width: susy-span(10 wider of 12);
        }

        img {
            box-shadow: 0 2px 80px 0 rgba(0,0,0,0.30);
        }
    }

    &:last-child {
        width: susy-span(5 narrow of 9 wide);

        @include mq($until: mobile) {
            width: susy-span(10 wider of 12);
            margin-top: respM(50);
        }

        .Text {
            width: susy-span(3.5 narrow of 5 narrow);
            margin-left: susy-span(1 wide of 5 narrow);

            @include mq($until: mobile) {
                width: 100%;
                margin-left: 0;
            }
        }
    }
}

.Text {
    margin-top: resp(90);

    @include mq($until: mobile) {
        margin-top: respM(50);
    }
}

.Fullsize {
    width: susy-span(8 narrow of 12);
    margin-left: susy-span(2 wide of 12);

    @include mq($until: mobile) {
        width: 100%;
        margin: 0;

        .Text {
            width: susy-span(10 narrow of 12);
            margin-left: susy-span(1 wide of 12);
        }
    }
}

.Content {
    position: relative;
}

.Player {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
}

.ImageBack {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    z-index: -1;

    @include respSize(height, 404, 317);
    @include respSize(margin, -45, -30);

}
