@import "../../scss/framework/tools";
@import "../../scss/framework/variables";

.Main {
    position: absolute;
    left: -1px;
    right: -1px;
    top: 100%;

    padding: resp(55) resp(55) resp(30);

    z-index: 2;

    box-sizing: border-box;
    background-color: $white;

    transition: top .4s;

    @include mq($until: mobile) {
        padding: respM(55) respM(55) respM(30);
    }
}

.Player {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Name {
    font-family: $Merriweather;
    font-weight: 700;
    @include fluid-type(16px, 18px);
}

.Title, .Time {
    font-size: 1.4rem;
    margin-bottom: 0 !important;
}

.TimelineWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;


    &:hover {
        .Timeline {
            transform: scaleY(6);
        }
    }

    @include mq($until: mobile) {
        margin-bottom: resp(20);
    }
}

.Timeline {
    position: relative;

    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: resp(15);

    background-color: $timelineBG;
    width: 100%;
    height: 1px;

    cursor: pointer;

    transition: transform .3s;

    @include mq($until: mobile) {
        margin-top: respM(15);
        margin-bottom: respM(15);
        margin-right: respM(10);
    }
}

.backTimeline {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

    width: 0;

    z-index: 1;
    opacity: .2;
}

.Progress {
    position: absolute;
    top: 0;
    left: 0;

    background: $progressBG;
    width: 0;
    height: 100%;

    transition: 0.25s;
}

.Controls {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 20px;

    > * {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.Close {
    position: absolute;
    top: resp(20);
    right: resp(20);

    @include mq($until: mobile) {
        top: respM(20);
        right: respM(20);
    }
}
